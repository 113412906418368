var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Generell informasjon"
    }
  }), _c('v-row', _vm._l(_vm.generalInfoCurriculumValues, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }