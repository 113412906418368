var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.chipItems.length > 0 ? _c('div', _vm._l(_vm.chipItems, function (chipItem, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('clickChip', chipItem);
        }
      }
    }, [_vm._t("default", null, {
      "chipItem": chipItem
    })], 2);
  }), 1) : _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.noChipsText)
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }