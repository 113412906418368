var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Filer"
    }
  }), _vm.curriculum ? _c('BaseFileList', {
    attrs: {
      "entity": "curriculum",
      "curriculumId": _vm.curriculum
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }