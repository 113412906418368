var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        var _vm$curriculumValues;

        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCurriculumList
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s((_vm$curriculumValues = _vm.curriculumValues) === null || _vm$curriculumValues === void 0 ? void 0 : _vm$curriculumValues.name) + " ")];
      },
      proxy: true
    }, !_vm.isExecutionPlan ? {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "editCurriculum"
          },
          on: {
            "click": _vm.editCurriculum
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Rediger ")], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [[_vm.curriculumValues ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('CurriculumDetailsCard', {
    attrs: {
      "curriculum": _vm.curriculumValues,
      "courseAreas": _vm.courseAreas
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.curriculumFileList.length > 0 ? _c('CurriculumFiles', {
    attrs: {
      "curriculum": _vm.curriculumValues,
      "curriculumFileList": _vm.curriculumFileList
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Læremidler"
    }
  }), _c('BaseChipGroup', {
    attrs: {
      "chipItems": _vm.curriculumValues.course.teachingMaterials
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var chipItem = _ref.chipItem;
        return [_c('CurriculumChipTeachingMaterialValue', {
          attrs: {
            "chipItem": chipItem
          }
        })];
      }
    }], null, false, 2245588662)
  })], 1)], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Samarbeidspartnere"
    }
  }), _c('BaseChipGroup', {
    attrs: {
      "chipItems": _vm.curriculumValues.partners
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var chipItem = _ref2.chipItem;
        return [_c('CurriculumChipPartnerValue', {
          attrs: {
            "chipItem": chipItem
          }
        })];
      }
    }], null, false, 1619852584)
  })], 1)], 1), _vm._l(_vm.collapsableTextFields, function (textField, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('CurriculumHtmlCard', {
      attrs: {
        "title": textField.label,
        "content": textField.value
      }
    })], 1);
  }), !_vm.isDraft && !_vm.isExecutionPlan ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('CourseList')], 1) : _vm._e()], 2) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }