var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getFilelist.length > 0 ? _c('div', [_vm._l(_vm.getFilelist, function (item, i) {
    return _c('v-chip', {
      key: i,
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openFile(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.originalFileName) + " ")]);
  }), _c('LightboxContentArea', {
    ref: "overlay"
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }