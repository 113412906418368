
import { api } from "@/api/api";
import { ApiCustomerOrgDto } from "@/api/generated/Api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CurriculumChipPartnerValue",
  props: {
    chipItem: {
      type: [Object, Number],
      required: true,
    },
  },
  setup(props) {
    const partnerChip = ref<ApiCustomerOrgDto>();
    onMounted(async () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        if (props.chipItem instanceof Object) {
          const chipItemObject = props.chipItem as ApiCustomerOrgDto;
          partnerChip.value = chipItemObject;
          return;
        }
        if (typeof props.chipItem === "number") {
          const { customerId, name, orgNumber, homePage, isActive } = (
            await api.customer.getCustomerOrgByIdAsync(props.chipItem)
          ).data.customer;
          partnerChip.value = { customerId, name, orgNumber, homePage, isActive };
        }
      });
    });

    return {
      partnerChip,
    };
  },
});
