
// TODO should this be a base component?
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CurriculumHtmlCard",
  components: { BaseSheet, BaseSheetHeader },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    displayText: false,
  }),
});
