var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('BaseSheetHeader', _vm._g(_vm._b({
          attrs: {
            "content": _vm.content,
            "displayText": _vm.displayText,
            "icon": _vm.icon,
            "title": _vm.title
          },
          on: {
            "click": function click($event) {
              _vm.displayText = !_vm.displayText;
            }
          }
        }, 'BaseSheetHeader', attrs, false), on))];
      }
    }])
  }, [_c('span', [_vm._v("Vis " + _vm._s(_vm.title))])]), _c('v-expand-transition', [_vm.displayText ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pa-2 pt-0 pb-0 grey--text text--darken-1"
  }, [_vm.content ? _c('span', {
    staticClass: "font-weight-bold",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  }) : _vm._e(), !_vm.content ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" - ")]) : _vm._e()])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }