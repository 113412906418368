
import { api } from "@/api/api";
import { ApiGetTeachingMaterialDto } from "@/api/generated/Api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CurriculumChipTeachingMaterialValue",
  props: {
    chipItem: {
      type: [Object, Number],
      required: true,
    },
  },
  setup(props) {
    const teachingMaterialChip = ref<ApiGetTeachingMaterialDto>();
    const isTeachingMaterial = (teachingMaterialChip: any): teachingMaterialChip is ApiGetTeachingMaterialDto =>
      teachingMaterialChip && typeof teachingMaterialChip === "object" && "category" in teachingMaterialChip;
    onMounted(async () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        if (isTeachingMaterial(props.chipItem)) {
          teachingMaterialChip.value = props.chipItem;
          return;
        }
        if (typeof props.chipItem === "number") {
          const teachingMaterialResponse = (await api.teachingmaterial.getTeachingMaterialAsync(props.chipItem)).data;
          teachingMaterialChip.value = teachingMaterialResponse;
        }
      });
    });

    return {
      teachingMaterialChip,
    };
  },
});
