var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-overlay', {
    attrs: {
      "value": _vm.overlay
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.closeOverlay.apply(null, arguments);
      }
    }
  }, [!_vm.getFile ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  }) : _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "primary",
      "fixed": "",
      "top": "",
      "left": ""
    },
    on: {
      "click": _vm.closeOverlay
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-close ")])], 1), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "primary",
      "fixed": "",
      "top": "",
      "right": "",
      "href": _vm.getFile.Url,
      "download": _vm.getFile.Name
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-cloud-download-outline ")])], 1), _c('img', {
    attrs: {
      "src": _vm.getFile.Url
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }